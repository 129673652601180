.transaction-tab {
    display: flex;
    background: $gradient-primary-transparent-linear-3, linear-gradient(-135deg, rgba(#111, 0.9) 0%, transparent 100%);
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: 16px;
    // backdrop-filter: blur(40px);

    .ant-radio-button-wrapper {
        flex: auto;
        text-align: center;
        border: 0;
        background: transparent;

        &:first-child {
            border-radius: 16px 0 0 16px;
        }

        &:last-child {
            border-radius: 0 16px 16px 0;
        }

        &:not(:first-child) {
            &::before {
                display: none;
            }
        }
    }

    .ant-radio-button-wrapper-checked {
        color: var(--color-text-primary-bg) !important;
        background: $gradient-primary !important;
    }
}