.menu-sidebar {
    position: absolute;
    top: 125px;
    left: 17px;
    right: 17px;
    bottom: 16px;
    margin: 0;
    padding: 0 28px 16px;
    overflow-x: hidden;
    overflow-y: auto;

    li {
        list-style: none;

        a {
            position: relative;
            display: flex;
            padding: 12px 0;
            color: $color-white;
            font-size: 24px;

            img {
                width: 24px;
                margin-right: 20px;
                transition: transform 0.25s ease;
                z-index: 1;
            }

            span {
                text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
                transition: transform 0.25s ease;
                z-index: 1;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -16px;
                bottom: 0;
                right: -16px;
                border-radius: 12px;
                box-shadow: inset 0 0 0 1px rgba($color-white, 0.075);
                background: $gradient-primary;
                opacity: 0;
                transition: all 0.25s ease;
            }

            &:hover {
                &:not(.active-menu) {
                    color: var(--color-text-primary-bg);

                    img {
                        transform: scale(1.1);
                    }

                    span {
                        transform: translateX(6px);
                    }

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        a.active-menu {
            span {
                color: var(--color-text-primary-bg);
            }

            &:before {
                opacity: 1;
                background: $gradient-primary;
            }
        }
    }
}

.bottombar-mobile {
    position: fixed;
    right: 0;
    bottom: -1px;
    left: 0;
    z-index: 1000;

    // @media screen and (min-width: $screen-lg) {
    //     display: none;
    // }

    .bg-wrapper {
        width: 100%;
        display: flex;
    }

    .bg-gradient-wrapper {
        flex: 1;
        background: $gradient-primary-bottom;
        margin: 0 -1px 0;

        &:first-child {
            border-radius: 16px 0 0 0;
        }

        &:last-child {
            border-radius: 0 16px 0 0;
        }
    }
}

.navigate-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 10px 10px 6px;
    margin: 0 auto;

    .navigate-container {
        display: flex;
        flex: 1;

        .navigate-item {
            position: relative;
            display: flex;
            flex: 1;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            align-items: center;
            color: var(--color-text-primary-bg);
            text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);

            &.active {
                color: $color-secondary;
            }

            i {
                font-size: 22px;
                margin-bottom: 4px;
            }

            span {
                font-size: 12px;
            }

            .navigate-main-icon {
                position: absolute;
                top: -48px;
                left: 50%;
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $gradient-secondary;
                border-radius: 50%;
                transform: translate(-50%);
                color: var(--color-text-secondary-bg);
                box-shadow: 0 0 10px $color-secondary, inset 0 0 2px #00000080;
                text-shadow: 1px 2px 4px var(--color-text-shadow-secondary-bg);

                i {
                    font-size: 28px;
                    animation: dance 1s infinite;
                }
            }
        }
    }

    @media (min-width: $screen-lg) {
        max-width: 640px;
    }
}

.menu-mobile-wrapper {
    padding: 0;
    display: grid;
    gap: 12px 32px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 12px;

    @media (max-width: 390px) {
        gap: 12px 16px;
        padding: 0;
    }

    @media (min-width: $screen-md) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (min-width: $screen-lg) {
        display: none;
    }
}

.menu-mobile {
    display: block;
    color: $color-white;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.75);
    font-size: 16px;
    text-align: center;
    white-space: nowrap;

    @media (max-width: 390px) {
        font-size: 14px;
    }
}

.menu-mobile-icon {
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    padding-top: 100%;
    border-radius: 16px;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $gradient-primary-shadow;
        // box-shadow: inset 0 0 0 1px rgba($color-white, 0.2);
        border-radius: 16px;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        transform: translate(-50%, -50%);
        filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.4));
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        transform: translate(-50%, -50%);
        font-size: 24px;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
}

.account-menu {
    background: $gradient-light;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: $border-radius-card;
    display: flex;
    align-items: center;
    padding: 16px;
    transition: all 0.25s ease;

    .anticon {
        margin-left: auto;
        color: $color-white;
        font-size: 20px;
        transition: all 0.25s ease;
    }

    .topbar-name {
        display: flex;
        align-items: center;
        transition: all 0.25s ease;
    }

    .account-menu-name {
        display: flex;
        align-items: center;
        color: $color-white;
        transition: all 0.25s ease;

        svg {
            margin-right: 16px
        }
    }

    svg {
        width: 24px;
        height: 24px;
    }

    // &:hover {
    //     border-color: hsla(var(--color-primary-hsl), 0.1);

    //     .anticon,
    //     .topbar-name,
    //     .account-menu-name {
    //         color: $color-primary;
    //     }
    // }
}

.game-menu {
    position: sticky;
    top: 80px;
    padding: 16px 8px;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: 16px;
}

.game-menu-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    border-radius: 12px;
    cursor: pointer;
    transition: color .25s ease;
    will-change: color;

    @media (min-width: $screen-md) {
        padding: 12px 4px;
        font-size: 22px;
    }

    img {
        width: 28px;
        margin-bottom: 4px;
        filter: grayscale(50%);
        transition: all .25s ease;
        will-change: transform, filter;

        @media (min-width: $screen-md) {
            width: 48px;
        }
    }

    &.active {
        color: $color-black;
        font-weight: 700;
        background: $gradient-primary;
        box-shadow: hsla(var(--color-primary-hsl), 20%) 0 10px 20px -20px;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        &:not(.active) {
            color: $color-primary;

            img {
                transform: scale(1.1);
                filter: grayscale(0%);
            }
        }
    }
}