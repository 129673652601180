.wallet-row {
    @media (min-width: $screen-xl) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 16px;
        row-gap: 16px;

        .wallet-col {
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
}

.wallet-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0;
    padding: 16px;
    color: var(--color-text-primary-bg);
    line-height: 1.25;
    background: $gradient-primary;
    border-radius: 16px;
    box-sizing: border-box;
    overflow: hidden;

    .wallet-gold-element {
        position: absolute;
        left: 0;
        bottom: -20px;
        right: 0;
        z-index: 0;
    }

    .wallet-char-element {
        position: absolute;
        bottom: 16px;
        right: 10px;
        z-index: 0;
        width: 130px;
        filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.3));

        &.dance {
            transform-origin: bottom center;
            animation: dance 2s infinite;
        }

        &.flying {
            transform: translateY(-5%);
            transform-origin: center center;
            animation: floater 1.5s infinite;
            transition: ease 0.5s;
        }
    }

    .credit-out-standing {
        position: relative;
        width: auto;
        height: 52px;

        .credit-out-standing-body {
            border-radius: 8px;
            background: rgba(#2d0000, 0.72) !important;
            color: #ff4d4f !important;
            backdrop-filter: blur(10px);
            display: inline-flex;
            align-items: center;
            padding: 8px;
            box-sizing: border-box;
            font-size: 20px;

            .anticon-info-circle {
                font-size: 16px;
                margin-left: 6px;
                cursor: pointer;
            }
        }
    }

    @media (min-width: $screen-md) {
        margin-bottom: 16px;

        .wallet-gold-element {
            bottom: -26px;
            width: 100%;
        }

        .credit-out-standing {
            .credit-out-standing-body {
                height: 100%;
                font-size: 24px;
                padding: 8px 16px;

                .anticon-info-circle {
                    font-size: 20px;
                    margin-left: 10px;
                }
            }
        }
    }

    @media (min-width: $screen-lg) {
        padding: 32px;

        .wallet-gold-element {
            bottom: -1px;
        }

        .wallet-char-element {
            bottom: 10px;
            right: 20px;
            width: auto;
            max-width: 210px;
        }
    }
}

.wallet-amount {
    font-size: 32px;
    font-weight: bold;
    margin-top: 2px;
    z-index: 1;

    @media (min-width: $screen-lg) {
        font-size: 48px;
    }
}

.wallet-other-card {
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    line-height: 1.25;
    background: $gradient-dark, rgba(#101010, 0.8);
    border-radius: 16px;
    overflow: hidden;

    @media (min-width: $screen-lg) {
        padding: 12px 16px;

        &:first-child {
            border-radius: 16px 16px 0 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        &:last-child {
            border-radius: 16px;
            margin-top: 16px;
        }
    }

    .reward-cashback {
        position: absolute;
        top: 8px;
        right: 8px;
        bottom: 8px;
        height: calc(100% - 16px) !important;
        box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
        line-height: 0.8;
        padding: 0 16px !important;
        font-size: 24px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: $screen-lg) {
            top: 12px;
            right: 12px;
            bottom: 12px;
            height: calc(100% - 24px) !important;
            padding: 12px 24px !important;
            font-size: 32px !important;
        }
    }

    .wallet-other-card-amount {
        box-sizing: border-box;
        color: $color-white;
        font-weight: bold;
        font-size: 18px !important;
        line-height: 1.5;

        @media (min-width: $screen-lg) {
            font-size: 20px !important;
        }
    }

    .wallet-other-card-totalturn {
        opacity: 0.8;
        font-size: 14px;
        padding-left: 4px;

        @media (min-width: $screen-lg) {
            font-size: 20px;
        }
    }

    .ant-btn-secondary {
        @media (max-width: $screen-lg) {
            height: 32px;
            padding: 0 8px;
            border-radius: 10px;
            margin-left: 8px;
            font-size: 20px;
        }
    }
}

.balance-other {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 12px;
    margin-bottom: 16px;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 0;
    
    &.show-turnover {
        margin-top: 12px;

        .wallet-other-card {
            &:first-child {
                border-radius: 16px 16px 0 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }

            &:last-child {
                border-radius: 0 0 16px 16px;
            }
        }
    }

    @media (min-width: $screen-md) {
        column-gap: 16px;
        row-gap: 16px;
    }

    @media (min-width: $screen-lg) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;
        row-gap: 0;
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-direction: row;
    }
}

.turn-balance-item {
    display: block;
}

.wallet-other-card-inner {
    .ant-typography-secondary {
        font-size: 14px;
    }

    .ant-btn-icon-only {
        padding: 0 !important;
        height: 18px !important;
        width: 18px !important;
        margin-right: 8px;

        @media (max-width: 390px) {
            width: 14px !important;
            height: 14px !important;

            i {
                font-size: 14px !important;
            }
        }
    }

    .wallet-other-card-amount {
        font-size: 28px;
    }

    @media (min-width: $screen-md) {
        .ant-typography-secondary {
            font-size: 16px;
        }
    }
}

.main-slider-container {
    border-radius: 12px;
    overflow: hidden;
    margin: 12px 0 0;

    img {
        max-height: 400px;
        animation: fadeIn 0.5s ease-in-out;
    }

    @media (min-width: $screen-md) {
        img {
            max-height: 500px;
        }
    }
}

.my-promotion {
    position: absolute;
    right: -1px;
    bottom: -1px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 0;
    max-width: 190px;
    backdrop-filter: blur(10px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 0 10px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    white-space: nowrap;

    span {
        font-size: 20px;
        margin-top: 2px;
        color: rgba(0, 0, 0, 0.5);
    }

    img {
        width: 14px;
        margin-right: 6px;
        filter: grayscale(100%);
    }

    &.get-bonus {
        background: rgba($color-success, 0.9);
        cursor: pointer;

        span {
            color: $color-white;
        }

        img {
            filter: grayscale(0);
        }
    }

    .my-promotion-body {
        padding: 2px 16px 2px 6px;
        will-change: transform;

        &.overflow {
            padding: 2px 0;
            animation: slide-in 10s infinite linear;
        }
    }

    @media (min-width: $screen-md) {
        max-width: 250px;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%) translateZ(0);
    }

    100% {
        transform: translateX(-100%) translateZ(0);
    }
}

.ant-space-gap-row-small {
    row-gap: 0px !important;
}