.ant-form-item-explain {
    margin-top: 4px;

    div {
        margin: 4px 0 8px;
        font-size: 14px !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
}

// Error
.ant-form-item-explain-error {
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.8);
}