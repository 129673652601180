.ranking-card {
    position: relative;
    padding: 16px;
    background: $gradient-light, rgba(0, 0, 0, 0.85);
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: $border-radius-card;
    overflow: hidden;
    line-height: 1;
    color: $color-white;

    &.top-ten {
        .ranking-card-num {
            position: absolute;
            left: -6px;
            bottom: -44px;
            font-size: 136px;
            font-weight: bold;
            letter-spacing: -14px;
            width: 90px;
            opacity: 0.2;
            background: linear-gradient(315deg, rgba($color-white, 1) 50%, rgba($color-white, 0.0) 90%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .ranking-card-icon {
            padding: 0 8px 0 40px;
        }
    }

    .ranking-card-body {
        display: flex;
        align-items: center;
    }

    .ranking-card-footer {
        border-top: 1px solid rgba($color-white, 0.1);
        margin-top: 12px;

        .ant-progress {
            position: relative;
            margin-bottom: 0;
            margin-top: 16px;

            .ant-progress-outer {
                background-color: rgba(#000, 0.5);
                border-radius: 12px;
            }

            .ant-progress-bg {
                background: $gradient-primary;
            }

            .ant-progress-inner {
                margin-top: -9px;
            }

            &.supreme {
                .ant-progress-bg {
                    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
                }

                &::before {
                    content: "";
                    z-index: -1;
                    position: absolute;
                    top: 3px;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 10px;
                    background: linear-gradient(0deg, #12c2e9 0%, #c471ed 50%, #f64f59 100%);
                    transform: translate3d(0px, 4px, 0) scale(0.95);
                    opacity: 1;
                    transition: filter 0.3s ease-in;
                    border-radius: inherit;
                    animation: colorBlur 2s infinite linear;
                }

                &::after {
                    content: "";
                    z-index: -1;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: inherit;
                    border-radius: inherit;
                }
            }
        }
    }

    .ranking-card-progress-info {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        letter-spacing: 1px;
        margin-top: 12px;
        margin-bottom: -6px;
    }

    .ranking-card-icon {
        padding-right: 8px;

        img {
            height: 48px;
            width: 48px;
            object-fit: cover;
        }
    }

    .ranking-card-name {
        line-height: 1.25;

        small {
            color: $text-color-secondary;
        }
    }

    .ranking-card-point {
        position: relative;
        margin-left: auto;

        span {
            background: $gradient-light;
            padding: 0 36px 0 12px;
            border-radius: $border-radius-card;
            box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        }

        img {
            position: absolute;
            top: -3px;
            right: 0;
            width: 28px;
            animation: dance 2s infinite;
        }
    }
}

.ranking-col {
    &:first-child {
        .ranking-card {
            background: $gradient-primary;
            color: var(--color-text-primary-bg);
            animation: pulseTopOne 2s infinite;

            &.top-ten {
                .ranking-card-num {
                    opacity: 0.6;
                    background: linear-gradient(315deg, rgba($color-black, 1) 60%, rgba($color-black, 0.0) 90%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    &:nth-child(2) {
        .ranking-card {
            background: $gradient-primary-transparent-linear !important;
            box-shadow: inset 0 0 0 2px hsla(var(--color-primary-hsl), 0.4);

            &.top-ten {
                .ranking-card-num {
                    opacity: 0.6;
                    background: linear-gradient(315deg, rgba($color-black, 1) 60%, rgba($color-black, 0.0) 90%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}