@import './../fontawesome-pro/css/all.css';

@font-face {
    font-family: "IBM Plex Sans Thai";
    src: url("../../webfonts/IBMPlexSansThai-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "IBM Plex Sans Thai";
    src: url("../../webfonts/IBMPlexSansThai-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "IBM Plex Sans Thai";
    src: url("../../webfonts/IBMPlexSansThai-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}