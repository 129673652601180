// Background
$background-base: #121416;

// Breakpoint
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

// Colors
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-tertiary: var(--color-tertiary);
$color-success: #52c41a;
$color-danger: #e84749;
$color-warning: #faad14;
$color-white: #fff;
$color-black: #000;
$color-grey-1: #a7a7a7;
$color-grey-2: #1b1b1b;
$color-grey-3: #333;

// Gradient
// $gradient-primary: linear-gradient(-325deg,
//     hsl(var(--color-primary-h),
//         var(--color-primary-s),
//         var(--color-primary-l)) 30%,
//     hsl(calc(var(--color-primary-h) + 50),
//         var(--color-primary-s),
//         calc(var(--color-primary-l) + 30%)) 100%);

// $gradient-primary-top: linear-gradient(180deg,
//     hsl(var(--color-primary-h),
//         var(--color-primary-s),
//         var(--color-primary-l)) 10%,
//     hsl(calc(var(--color-primary-h) + 50),
//         var(--color-primary-s),
//         calc(var(--color-primary-l) + 30%)) 100%);

// $gradient-primary-bottom: linear-gradient(0deg,
//     hsl(var(--color-primary-h),
//         var(--color-primary-s),
//         var(--color-primary-l)) 10%,
//     hsl(calc(var(--color-primary-h) + 50),
//         var(--color-primary-s),
//         calc(var(--color-primary-l) + 30%)) 100%);

// $gradient-primary-shadow: linear-gradient(45deg,
//     hsl(var(--color-primary-h),
//         var(--color-primary-s),
//         calc(var(--color-primary-l) + 8%)) 30%,
//     hsl(calc(var(--color-primary-h) + 100),
//         var(--color-primary-s),
//         calc(var(--color-primary-l) + 30%)) 100%);

$gradient-primary: linear-gradient(-325deg, var(--color-primary-start) 20%, var(--color-primary-end) 100%);
$gradient-primary-reverse: linear-gradient(325deg, var(--color-primary-start) 20%, var(--color-primary-end) 100%);
$gradient-primary-top: linear-gradient(180deg, var(--color-primary-start) 0%, var(--color-primary-end) 100%);
$gradient-primary-bottom: linear-gradient(0deg, var(--color-primary-start) 0%, var(--color-primary-end) 100%);
$gradient-primary-shadow: linear-gradient(45deg, var(--color-primary-start) 0%, var(--color-primary-end) 100%);
$gradient-primary-transparent-linear: linear-gradient(-325deg, $color-primary 0%, transparent 90%);

$gradient-primary-transparent-linear-2: linear-gradient(-135deg,
    hsla(var(--color-primary-h),
        var(--color-primary-s),
        var(--color-primary-l), 0.3) 0%,
    hsla(var(--color-primary-h),
        var(--color-primary-s),
        var(--color-primary-l), 0) 100%);

$gradient-primary-transparent-linear-3: linear-gradient(25deg,
    hsla(var(--color-primary-h),
        var(--color-primary-s),
        var(--color-primary-l), 0.3) 0%,
    hsla(var(--color-primary-h),
        var(--color-primary-s),
        var(--color-primary-l), 0) 80%);

$gradient-secondary: linear-gradient(-325deg,
    hsl(var(--color-secondary-h),
        var(--color-secondary-s),
        var(--color-secondary-l)) 30%,
    hsl(calc(var(--color-secondary-h) + 50),
        var(--color-secondary-s),
        calc(var(--color-secondary-l) + 40%)) 100%);

$gradient-secondary-dark: linear-gradient(-325deg,
    hsl(var(--color-secondary-h),
        var(--color-secondary-s),
        var(--color-secondary-l)) 30%,
    hsl(var(--color-secondary-h),
        var(--color-secondary-s),
        calc(var(--color-secondary-l) + 30%)) 100%);

$gradient-tertiary: linear-gradient(-325deg,
    hsl(var(--color-tertiary-h),
        var(--color-tertiary-s),
        var(--color-tertiary-l)) 30%,
    hsl(calc(var(--color-tertiary-h) + 50),
        var(--color-tertiary-s),
        calc(var(--color-tertiary-l) + 30%)) 100%);

$gradient-red: linear-gradient(107.2deg, rgb(150, 15, 15) 10.6%, rgb(247, 0, 0) 91.1%);

$gradient-dark-soft: linear-gradient(-325deg, rgba(0, 0, 0, .09) 30%, rgba(0, 0, 0, 0) 100%);
$gradient-dark: linear-gradient(-325deg, rgba(0, 0, 0, .7) 30%, rgba(0, 0, 0, 0) 100%);
$gradient-light-soft: linear-gradient(-325deg, rgba(255, 255, 255, .09) 30%, rgba(255, 255, 255, 0) 100%);
$gradient-light: linear-gradient(-325deg, rgba(255, 255, 255, .15) 30%, rgba(255, 255, 255, 0) 100%);
$gradient-light-reverse: linear-gradient(325deg, rgba(255, 255, 255, .15) 30%, rgba(255, 255, 255, 0) 100%);
$gradient-lighter: linear-gradient(-325deg, rgba(255, 255, 255, .2) 30%, rgba(255, 255, 255, 0.025) 100%);
$gradient-success: linear-gradient(-325deg, rgba($color-success, .15) 30%, rgba($color-success, 0) 100%);
$gradient-warning: linear-gradient(-325deg, rgba($color-warning, .15) 30%, rgba($color-warning, 0) 100%);
$gradient-danger: linear-gradient(-325deg, rgba($color-danger, .15) 30%, rgba($color-danger, 0) 100%);

// Sidebar
/* hex to filter : https://isotropic.co/tool/hex-color-to-css-filter/ */
$sidebar-icon-color-default: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); // White Color
$sidebar-icon-color-active: invert(83%) sepia(44%) saturate(4415%) hue-rotate(2deg) brightness(107%) contrast(105%); // Yellow Color

// Styles
$color-line: #434343;
$border-radius-card: 20px;

// Typography
$text-color: $color-white;
$text-color-secondary: rgba($color-white, 75%);