.footer {
    padding: 24px 16px;
    margin: 24px 0 0;
    background: rgba(0, 0, 0, 0.75);
    // background: rgba(0, 0, 0, 0.25);
    // backdrop-filter: blur(50px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
    border-radius: 12px;
    text-align: center;

    @media screen and (min-width: $screen-lg) {
        padding: 40px 24px;
    }

    .footer-info {
        h1 {
            font-size: 24px;
            color: $color-primary;
            margin: 0 0 8px;
            line-height: 1.25;
        }

        p {
            font-size: 16px;
            margin: 0 0 20px;
            color: rgba(255, 255, 255, 0.88)
        }
    }

    .footer-tags {
        a {
            .ant-tag {
                margin-bottom: 8px
            }

            &:hover {
                .ant-tag {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }

    .footer-partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 16px;

        img {
            width: 36px;
            margin: 4px;
            align-self: center;
            justify-self: center;
            border-radius: 10%;

            @media screen and (min-width: $screen-lg) {
                width: 40px;
            }
        }
    }

    // .footer-partners.bank {
    //     display: none;

    //     img {
    //         width: 36px;
    //         margin: 4px;
    //         align-self: center;
    //         justify-self: center;
    //         border-radius: 10%;

    //         @media screen and (min-width: $screen-lg) {
    //             width: 40px;
    //         }
    //     }


    //     @media(min-width: $screen-md) {
    //         display: flex;
    //         flex-wrap: wrap;
    //         justify-content: center;
    //         margin-top: 16px;

    //     }
    // }
}


// .footer .carousel-container {
//     overflow: hidden;
//     position: relative;
//     height: 50px;
//     width: 100%;

//     @media(min-width: $screen-md) {
//         display: none;
//     }

//     .carousel-track {
//         display: flex;
//         position: absolute;
//         left: 0;
//         justify-content: center;
//         align-items: center;
//         height: 50px;
//         gap: 8px;
//         width: 200%;
//         animation: slide 15s linear infinite;

//         .carousel-card {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 100%;
//             height: 100%;
//             border-radius: 20%;
//         }

//         .carousel-card>img {
//             height: 36px;
//             width: 36px;
//             border-radius: 10%;
//         }

//     }
// }


// @keyframes slide {
//     0% {
//         left: 0;
//     }

//     50% {
//         left: -100%;
//     }

//     100% {
//         left: 0%;
//     }
// }

// .footer .carousel-container2 {
//     overflow: hidden;
//     position: relative;
//     height: 50px;
//     width: 100%;

//     @media(min-width: $screen-md) {
//         display: none;
//     }

//     .carousel-track2 {
//         display: flex;
//         position: absolute;
//         left: 0;
//         justify-content: center;
//         align-items: center;
//         height: 50px;
//         gap: 8px;
//         width: 200%;
//         animation: slide2 17s linear infinite;

//         .carousel-card {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 100%;
//             height: 100%;
//             border-radius: 20%;
//         }

//         .carousel-card>img {
//             height: 36px;
//             width: 36px;
//             border-radius: 10%;
//         }
//     }
// }


// @keyframes slide2 {
//     0% {
//         left: 0;
//     }

//     50% {
//         left: -100%;
//     }

//     100% {
//         left: 0;
//     }
// }