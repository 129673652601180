.Toastify__toast {
    font-family: inherit !important;
}

.Toastify__toast--success {
	background: linear-gradient(-135deg, $color-success, rgba($color-success, 0)),
        rgba($color-black, 0.4) !important;
    backdrop-filter: blur(10px) contrast(60%);

    .Toastify__progress-bar {
        background: rgba($color-success, 1);
    }
}

.Toastify__toast--error {
	background: linear-gradient(-135deg, $color-danger, rgba($color-danger, 0)),
        rgba($color-black, 0.4) !important;
    backdrop-filter: blur(10px) contrast(60%);

    .Toastify__progress-bar {
        background: rgba($color-danger, 1);
    }
}