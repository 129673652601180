.transaction-card {
	background: $gradient-primary-transparent-linear-3, linear-gradient(-135deg, rgba(#111, 0.9) 0%, transparent 100%);
	border-radius: $border-radius-card;
	box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
	margin-bottom: 16px;

	.transaction-header,
	.transaction-body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 1;
	}

	.transaction-header {
		border-bottom: 1px solid rgba($color-white, 0.1);
		padding: 12px 16px;
	}

	.transaction-body-remark {
		padding: 12px 16px 0;
	}

	.transaction-body {
		padding: 12px 16px;
	}

	.transaction-footer {
		padding: 0 16px 20px;
	}

	.transaction-remark {
		background: #2a1215;
		border: 1px solid #58181C;
		border-radius: 12px;
		font-size: 22px;
		color: $color-danger;
		padding: 6px 16px;
		margin-bottom: 12px;
	}

	.transaction-title {
		color: var(--color-link);
		font-size: 20px;
		font-weight: bold;
		line-height: 1;
	}

	.support-btn {
		width: 100%;
	}

	.ant-tag {
		font-size: 20px;
		margin-right: 0;
		padding: 6px 8px;
	}

	@media (min-width: $screen-md) {
		.transaction-header {
			padding: 16px 24px;
		}

		.transaction-body {
			padding: 16px 24px;
		}

		.transaction-body-remark {
			padding: 16px 24px 0;
		}

		.transaction-footer {
			display: flex;
			align-items: flex-start;
			padding: 0 24px 24px;
		}

		.transaction-remark {
			flex: auto;
			font-size: 22px;
			margin-bottom: 0;
			margin-right: 16px;
			padding: 12px 16px;
		}

		.ant-tag {
			font-size: 22px;
		}
	}
}